/*---------------------------
 CSS Index  
****************************

1. variables
2. typography
3. spacing
4. reset
5. forms
6. mixins
7. shortcode
8. common
9. animations
10. text-animation
11. sal animation
12. header
13. nav
14. breadcrumb
15. mobile-menu
16. button
17. swiper
18. banner
19. about
20. service
21. cta
22. business-goal
23. counterup
24. gallery
25. project
26. trusted-client
27. team
28. feature
29. testimonial
30. pricing
31. blog
32. appoinment
33. faq
34. contact
35. map-area
36. team-details
37. working-process
38. back-totop
39. preloader
40. footer

----------------------------*/

/* Default  */
@import'default/variables';
@import'default/typography';
@import'default/spacing';
@import'default/reset';
@import'default/forms';
@import'default/mixins';
@import'default/shortcode';
@import'default/common';
@import'default/animations';
@import'default/text-animation';
@import'default/sal';

/* Header style hear  */
@import'header/header';
@import'header/nav';
@import'header/breadcrumb';
@import'header/mobile-menu';

/* elements style hear  */
@import'elements/button';
@import'elements/swiper';
@import'elements/banner';
@import'elements/about';
@import'elements/service';
@import'elements/cta';
@import'elements/business-goal';
@import'elements/counterup';
@import'elements/gallery';
@import'elements/project';
@import'elements/trusted-client';
@import'elements/team';
@import'elements/feature';
@import'elements/testimonial';
@import'elements/pricing';
@import'elements/blog';
@import'elements/appoinment';
@import'elements/faq';
@import'elements/contact';
@import'elements/map-area';
@import'elements/team-details';
@import'elements/working-process';
@import'elements/back-totop';
@import'elements/preloader';
@import'elements/footer';