

// appoinment atyle area

.single-circle-progress-inner{
    background-image: url(../images/appoinment/01.png);
    height: 320px;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
    background-size: contain;
    align-items: center;
    @media #{$md-layout} {
      height: 369px;
    }
    @media #{$sm-layout} {
      height: 265px;
    }
    @media #{$large-mobile} {
      height: 495px;
    }
    @media screen and (max-width: 569px) {
      height: 600px;
    }
    @media screen and (max-width: 510px) {
      height: 525px;
    }
    @media screen and (max-width: 460px) {
      height: 490px;
    }
    @media screen and (max-width: 430px) {
      height: 440px;
    }
    @media screen and (max-width: 390px) {
      height: 400px;
    }
    @media screen and (max-width: 365px) {
      height: 330px;
    }
    .title{
        margin-top: 85px;
        color: #fff;
        @media #{$sm-layout} {
          margin-top: 63px;
        }
        @media screen and (max-width: 569px) {
          margin-top: 200px !important;
        }
        @media screen and (max-width: 469px) {
          margin-top: 160px !important;
        }
        @media screen and (max-width: 466px) {
          margin-top: 130px !important;
        }
        @media screen and (max-width: 350px) {
          margin-top: 130px !important;
        }
    }
}

.single-circle-progress-inner{
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 360px) {
      margin-left: 12px;
    }
    @media screen and (max-width: 340px) {
      margin-left: 7px;
    }
}

.progress {
    width: 150px;
    height: 150px !important;
    float: left; 
    line-height: 150px;
    background: none;
    margin: 20px;
    box-shadow: none;
    position: relative;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-73%);
  }
  .progress:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 8px solid #434653;
    position: absolute;
    top: 0;
    left: 0;
  }
  .progress>span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }
  .progress .progress-left {
    left: 0;
  }
  .progress .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 8px;
    border-style: solid;
    position: absolute;
    top: 0;
  }
  .progress .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
  }
  .progress .progress-right {
    right: 0;
  }
  .progress .progress-right .progress-bar {
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
    animation: loading-1 1.8s linear forwards;
  }
  .progress .progress-value {
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0);
    font-size: 35px;
    color: #fff;
    line-height: 135px;
    text-align: center;
    position: absolute;
    top: 5%;
    left: 5%;
  }
  .progress.red .progress-bar {
    border-color: red;
  }
  .progress.red .progress-left .progress-bar {
    animation: loading-2 1.5s linear forwards 1.8s;
  }
  .progress.yellow .progress-bar {
    border-color: #fdba04;
  }
  .progress.yellow .progress-right .progress-bar {
    animation: loading-3 1.8s linear forwards;
  }
  .progress.yellow .progress-left .progress-bar {
    animation: none;
  }
  @keyframes loading-1 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
  @keyframes loading-2 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(125deg);
      transform: rotate(125deg);
    }
  }
  @keyframes loading-3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
    }
  }


  .background-contact-appoinment{
    background: #F6F6F6;
    .contact-form-area-one{
        background-image: url(../images/appoinment/03.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center left;
        background-color: rgba(255, 255, 255, 0);
        padding: 165px 120px 120px 120px;
        margin-left: -26px;
        @media #{$large-mobile} {
          padding: 60px;
        }
        @media #{$small-mobile} {
          padding: 60px 10px 60px 40px;
        }
        .title{
            color: #fff;
        }
        input{
            background: #212329;
            color: #fff;
            &::-webkit-input-placeholder { 
              color:#fff!important;
              font-weight: 400 !important;
              font-size: 16px !important;
              line-height: 21px !important;
            }
        }
        textarea{
          color: #fff;
            background: #212329;
            &::-webkit-input-placeholder { 
              color:#fff!important;
              font-weight: 400 !important;
              font-size: 16px !important;
              line-height: 21px !important;
            }
        }
    }
  }


.contact-image-one{
  &.appoinment{
    margin-right: -123px;
    img{
      @media #{$md-layout} {
        width: 100%;
      }
      @media #{$sm-layout} {
        width: 100%;
      }
    }
  }
}

.rts-contact-area.contact-one.appoinment::after{
  display: none;
}
.appoinment-team{
  padding-bottom: 210px;
  @media #{$md-layout} {
    padding-bottom: 80px;
  }
  @media #{$sm-layout} {
    padding-bottom: 60px;
  }
}