// breadcrumb area

.breadcrumb-bg{
    background-image: url(../images/breadcrumb/01.jpg);
}

.rts-breadcrumb-area{
    padding-top: 153px;
    padding-bottom: 153px;
    @media #{$sm-layout} {
        padding: 80px 0;
    }
    .title{
        color: #fff;
        margin-bottom: 0;
        @media #{$sm-layout} {
            font-size: 30px;
        }
    }
    .bread-tag{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 12px 30px;
        background: #fff;
        border-radius: 31px;
        box-shadow: 0px 9px 18px rgba(24, 16, 16, 0.05);
        max-width: max-content;
        margin-left: auto;
        @media #{$sm-layout} {
            margin: auto;
            margin-top: 30px;
        }
        a{
            margin-right: 5px;
            font-weight: 700;
            &.active{
                margin-left: 5px;
                color: #1C2539;
                margin-right: 0;
            }
        }
        span{
            color: var(--color-primary);
        }
    }
}